.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

body {
  font-family: Arial, sans-serif;
}

.white-link {
  color: white;
}


.mission-box {
  background-color: #f5f5f5; /* Lighter background color */
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  margin: 20px auto; /* Centered horizontally */
  border: 1px solid #ccc; /* Border added */
  max-width: 800px; /* Limit box width */
  color: black; /* Text color for readability */
}

.mission-statement {
  margin: 0;
}

