body {
  background-color: #0C64DA; /* Set the background color of the body */
  color: white;
}

.custom-navbar {
  background-color: #0C64DA;
}

.app-container {
  background-color: #0C64DA;
}

